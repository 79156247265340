import axios from 'axios';
import {
  extractToken,
  isBrowser,
  handleResponseError,
  requestAnonymBase,
} from '../utils';
// import mixpanel from '../mixpanel';

const login = (username, password) => {
  return axios
    .request({
      method: 'post',
      url: `${
        isBrowser
          ? process.env.GATSBY_CSR_SERVER_DOMAIN
          : process.env.GATSBY_SSR_SERVER_DOMAIN
      }/api/token/`, // TODO: Move endpoint to /core
      data: { username, password },
    })
    .then((response) => {
      const userWithDetails = extractToken(response.data);
      localStorage.setItem('user', JSON.stringify(userWithDetails));

      // if (userWithDetails) {
      //   mixpanel.identify(userWithDetails.userId);
      //   mixpanel.people.set({
      //     $name: userWithDetails.username,
      //     $email: userWithDetails.email,
      //     shopId: userWithDetails.shopId,
      //     shopName: userWithDetails.shopName,
      //     role: userWithDetails.role,
      //   });
      // }

      return userWithDetails;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        return Promise.reject(['null']);
      }
      return handleResponseError(error.response);
    });
  // .catch((errorResponse) => handleResponseError(errorResponse));
};

const passwordReset = (email) =>
  requestAnonymBase('post', '/core/api/auth/password_reset_request/', { email })
    .then((response) => response.data)
    .catch(handleResponseError);

export default {
  login,
  passwordReset,
};

import {
  requestBase,
  handleResponseError,
  requestAnonymBase,
  request,
} from '../utils';

const getEmployees = ({ shopIdList }) =>
  requestBase('get', '/core/api/employees/', null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getServices = ({ shopIdList }) =>
  requestBase('get', '/core/api/services/', null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getProducts = ({ shopIdList }) =>
  requestBase('get', '/core/api/products/', null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listSales = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  search = '',
}) =>
  requestBase('get', '/core/api/transactions/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getSale = ({ saleId }) =>
  requestBase('get', `/core/api/transactions/${saleId}/`)
    .then((response) => response.data)
    .catch(handleResponseError);

const purchase = (purchaseObj) =>
  requestBase('post', '/core/api/purchase/', purchaseObj)
    .then((response) => response.data)
    .catch(handleResponseError);

const listTillClosings = ({ shopId, startDate, endDate, onlyLatest }) =>
  requestBase('get', '/core/api/till-close-logs/', null, {
    shopId,
    startDate,
    endDate,
    onlyLatest,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getTillClosing = ({ shopId, closeDate }) =>
  requestBase('get', '/core/api/till-close-logs/daily/', null, {
    closeDate,
    shopId,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const closeTill = (closeTillData) =>
  requestBase('post', '/core/api/till-close-logs/', closeTillData)
    .then((response) => response.data)
    .catch(handleResponseError);

const createMiscTransaction = (transactionData) =>
  requestBase('post', '/core/api/create-misc-transaction/', transactionData)
    .then((response) => response.data)
    .catch(handleResponseError);

const voidTransaction = (voidData) =>
  requestBase('post', '/core/api/void-transaction/', voidData)
    .then((response) => response.data)
    .catch(handleResponseError);

const saveService = (serviceId, serviceData) =>
  requestBase('patch', `/core/api/services/${serviceId}/`, serviceData)
    .then((response) => response.data)
    .catch(handleResponseError);

const deleteService = (serviceId) =>
  requestBase('delete', `/core/api/services/${serviceId}/`)
    .then((response) => response.data)
    .catch(handleResponseError);

const addService = (serviceData) =>
  requestBase('post', '/core/api/services/', serviceData)
    .then((response) => response.data)
    .catch(handleResponseError);

const saveProduct = (productId, productData) =>
  requestBase('patch', `/core/api/products/${productId}/`, productData)
    .then((response) => response.data)
    .catch(handleResponseError);

const deleteProduct = (productId) =>
  requestBase('delete', `/core/api/products/${productId}/`)
    .then((response) => response.data)
    .catch(handleResponseError);

const addProduct = (productData) =>
  requestBase('post', '/core/api/products/', productData)
    .then((response) => response.data)
    .catch(handleResponseError);

const getEmployee = (employeeId, shopIdList) =>
  requestBase('get', `/core/api/employees/${employeeId}/`, null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const saveEmployee = (employeeId, employeeData, shopIdList) =>
  requestBase(
    'patch',
    `/core/api/employees/${employeeId}/`,
    employeeData,
    {
      shopIdList,
    },
    {
      'Content-Type': 'multipart/form-data',
    },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const deleteEmployee = (employeeId) =>
  requestBase('delete', `/core/api/employees/${employeeId}/`, null, null)
    .then((response) => response.data)
    .catch(handleResponseError);

const addEmployee = (employeeData) =>
  requestBase('post', '/core/api/employees/', employeeData, null, {
    'Content-Type': 'multipart/form-data',
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const useProduct = (useProductData) =>
  requestBase('post', '/core/api/usages/', useProductData)
    .then((response) => response.data)
    .catch(handleResponseError);

const listUsage = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  search = '',
}) =>
  requestBase('get', '/core/api/usages/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listUsageStats = (shopIdList, startDate, endDate) =>
  requestBase('get', '/core/api/usages/stats/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getUsage = (usageId) =>
  requestBase('get', `/core/api/usages/${usageId}/`)
    .then((response) => response.data)
    .catch(handleResponseError);

const voidUsage = (usageId, voidData) =>
  requestBase('post', `/core/api/usages/${usageId}/void/`, voidData)
    .then((response) => response.data)
    .catch(handleResponseError);

const getRevenueSummary = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/summary/revenue/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getCustomerSummary = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/summary/customer/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getSalesSummary = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  discountFilter = false,
  search = '',
}) =>
  requestBase('get', '/core/api/summary/sales/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    discountFilter,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getMiscSalesSummary = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  search = '',
}) =>
  requestBase('get', '/core/api/summary/misc-sales/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getUsageSummary = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  search = '',
}) =>
  requestBase('get', '/core/api/summary/usage/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getTipSummary = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/summary/tip/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getPerformanceBase = (action, shopIdList, startDate, endDate) =>
  requestBase('get', `/core/api/performance/${action}/`, null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getProductServicePerformance = ({ shopIdList, startDate, endDate }) =>
  getPerformanceBase('product-service', shopIdList, startDate, endDate);
const getPerformanceStats = ({ shopIdList, startDate, endDate }) =>
  getPerformanceBase('stats', shopIdList, startDate, endDate);
const getTillShortage = ({ shopIdList, startDate, endDate }) =>
  getPerformanceBase('till-shortage', shopIdList, startDate, endDate);

const getServiceSalesPerformance = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  search = '',
}) =>
  requestBase('get', '/core/api/performance/service-sales/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getProductSalesPerformance = ({
  shopIdList,
  startDate,
  endDate,
  pagination,
  search = '',
}) =>
  requestBase('get', '/core/api/performance/product-sales/', null, {
    shopIdList,
    startDate,
    endDate,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getProductInventory = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/product-inventory/inventory/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const addProductInventory = (inventoryData) =>
  requestBase('post', '/core/api/product-inventory/', inventoryData)
    .then((response) => response.data)
    .catch(handleResponseError);

const listInventoryInputs = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/product-inventory/input-list/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const voidInventoryInput = (voidData) =>
  requestBase('post', '/core/api/product-inventory/void/', voidData)
    .then((response) => response.data)
    .catch(handleResponseError);

const addSalaryRule = (ruleData) =>
  requestBase('post', '/core/api/salary-rules/', ruleData)
    .then((response) => response.data)
    .catch(handleResponseError);

const listSalaryRules = ({ shopIdList }) =>
  requestBase('get', '/core/api/salary-rules/', null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const deleteSalaryRule = (ruleId) =>
  requestBase('delete', `/core/api/salary-rules/${ruleId}/`)
    .then((response) => response.data)
    .catch(handleResponseError);

const assignSalaryRule = (assignmentData) =>
  requestBase('post', '/core/api/salary-rule-assignments/', assignmentData)
    .then((response) => response.data)
    .catch(handleResponseError);

const unAssignSalaryRule = (ruleId, employeeId, shopIdList) =>
  requestBase(
    'delete',
    `/core/api/salary-rule-assignments/${employeeId}/`,
    null,
    {
      ruleId,
      shopIdList,
    },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const listSalaryAssignments = ({ shopIdList }) =>
  requestBase('get', '/core/api/salary-rule-assignments/', null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listBookings = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/bookings/', null, {
    shopIdList,
    bookingStartDate: startDate,
    bookingEndDate: endDate,
    limit: 1000,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const cancelBooking = ({ bookingId, cancelData }) =>
  requestBase('delete', `/core/api/bookings/${bookingId}/`, cancelData)
    .then((response) => response.data)
    .catch(handleResponseError);

const cancelBookingCustomer = (shopId, token, cancelReason) =>
  requestAnonymBase(
    'post',
    `/core/api/public/shops/${shopId}/cancel-appointment/`,
    { token, cancelReason },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const feedbackBookingCustomer = (shopId, token, feedback, stars) =>
  requestAnonymBase(
    'post',
    `/core/api/public/shops/${shopId}/booking-feedback/`,
    {
      token,
      feedback,
      stars,
    },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const listSchedules = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/schedules/?limit=700', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listEmployeeSchedules = ({ shopIdList, barberId, startDate, endDate }) =>
  requestBase('get', '/core/api/schedules/employee/', null, {
    barberId,
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listDailySchedules = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/schedules/daily/', null, {
    shopIdList,
    bookingStartDate: startDate,
    bookingEndDate: endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const calculateSalaries = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/salary-rule-assignments/calculate/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getSalaryRule = (salaryRuleId) =>
  requestBase('get', `/core/api/salary-rules/${salaryRuleId}/`, null, null)
    .then((response) => response.data)
    .catch(handleResponseError);

const getSettings = ({ shopId }) =>
  requestBase('get', `/core/api/settings/${shopId}/`, null, null)
    .then((response) => response.data)
    .catch(handleResponseError);

const getOnboarding = ({ shopId }) =>
  requestBase('get', `/core/api/settings/${shopId}/onboarding/`, null, null)
    .then((response) => response.data)
    .catch(handleResponseError);

const saveSettings = ({ shopId, settingsData }) =>
  requestBase('patch', `/core/api/settings/${shopId}/`, settingsData, null, {
    'Content-Type': 'multipart/form-data',
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getPublicShopDetails = (shopId) =>
  requestAnonymBase('get', `/core/api/public/shops/${shopId}/`, null, null)
    .then((response) => response.data)
    .catch(handleResponseError);

const getPublicShopAppointments = ({
  shopId,
  barberId,
  serviceId,
  bookingDate,
}) =>
  requestAnonymBase(
    'get',
    `/core/api/public/shops/${shopId}/appointments/`,
    null,
    {
      shopId,
      barberId,
      serviceId,
      bookingDate,
    },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const getPublicShopAppointmentsV2 = ({
  shopId,
  barberIds,
  serviceIds,
  bookingDate,
}) =>
  requestAnonymBase(
    'get',
    `/core/api/public/shops/${shopId}/appointments/`,
    null,
    {
      shopId,
      barberIds,
      serviceIds,
      bookingDate,
    },
    { Accept: 'application/json; version=2.0' },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const getPublicShopAvailability = ({ shopId, barberId, startDate, endDate }) =>
  requestAnonymBase(
    'get',
    `/core/api/public/shops/${shopId}/availabilities/`,
    null,
    {
      shopId,
      barberId,
      startDate,
      endDate,
    },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const bookAppointment = (shopId, bookingData) =>
  requestAnonymBase(
    'post',
    `/core/api/public/shops/${shopId}/book-appointment/`,
    bookingData,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const bookAppointmentV2 = (shopId, bookingData) =>
  requestAnonymBase(
    'post',
    `/core/api/public/shops/${shopId}/book-appointment/`,
    bookingData,
    null,
    { Accept: 'application/json; version=2.0' },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const saveNextServiceBaseIDs = (serviceData) =>
  requestBase('post', '/core/api/services/next-service-base-ids/', serviceData)
    .then((response) => response.data)
    .catch(handleResponseError);

const saveDisableServiceBaseIDs = (serviceData) =>
  requestBase(
    'post',
    '/core/api/services/disable-service-base-ids/',
    serviceData,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const bookAppointmentAuthenticated = (shopId, bookingData) =>
  requestBase(
    'post',
    `/core/api/public/shops/${shopId}/book-appointment/`,
    bookingData,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const updateAppointmentAuthenticated = (shopIdList, bookingId, bookingData) =>
  requestBase('patch', `/core/api/bookings/${bookingId}/`, bookingData, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const messageAppointmentAuthenticated = ({ bookingId, message }) =>
  requestBase('post', `/core/api/bookings/${bookingId}/message/`, { message })
    .then((response) => response.data)
    .catch(handleResponseError);

const listClients = ({ shopIdList, search, pagination }) =>
  requestBase('get', '/core/api/clients/', null, {
    shopIdList,
    search,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const saveClientNotes = ({ bookingId, notes }) =>
  requestBase('put', `/core/api/bookings/${bookingId}/notes/`, { notes })
    .then((response) => response.data)
    .catch(handleResponseError);

const downloadClients = ({ shopIdList, search }) =>
  requestBase('get', '/core/api/clients/download/', null, {
    shopIdList,
    search,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getClient = ({ shopIdList, email }) =>
  requestBase('get', `/core/api/clients/${email}/`, null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const deleteClient = ({ shopIdList, email }) =>
  requestBase('post', `/core/api/clients/${btoa(email)}/delete/`, null, {
    shopIdList,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const updateAvailability = ({ shopIdList, availabilityId, availabilityData }) =>
  requestBase(
    'put',
    `/core/api/schedules/${availabilityId}/`,
    availabilityData,
    { shopIdList },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const createAvailability = ({ shopIdList, availabilityData }) =>
  requestBase('post', '/core/api/schedules/', availabilityData, { shopIdList })
    .then((response) => response.data)
    .catch(handleResponseError);

const addOrUpdateManyAvailability = ({ shopIdList, availabilityDataList }) =>
  requestBase(
    'post',
    '/core/api/schedules/add-or-update-many/',
    availabilityDataList,
    { shopIdList },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const listSalaryDeductions = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/deductions/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const addSalaryDeduction = ({ deductionData }) =>
  requestBase('post', '/core/api/deductions/', deductionData)
    .then((response) => response.data)
    .catch(handleResponseError);

const voidSalaryDeduction = ({ deductionId, voidData }) =>
  requestBase('post', `/core/api/deductions/${deductionId}/void/`, voidData)
    .then((response) => response.data)
    .catch(handleResponseError);

const forgotPassword = (forgotPasswordData) =>
  requestAnonymBase(
    'post',
    '/core/api/users/forgot-password/',
    forgotPasswordData,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const resetPassword = (resetPasswordData) =>
  requestAnonymBase(
    'post',
    '/core/api/users/reset-password/',
    resetPasswordData,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const signupEmail = (data) =>
  request(
    'post',
    'https://script.google.com/macros/s/AKfycbyjTGE-HQw3UjgWmATn8rFERoFDIBUja6xjoluEqGsbs6O4x83Nhwn9_l5ATZBQEVGe/exec',
    data,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const signup = (data) =>
  request(
    'post',
    'https://script.google.com/macros/s/AKfycbyjTGE-HQw3UjgWmATn8rFERoFDIBUja6xjoluEqGsbs6O4x83Nhwn9_l5ATZBQEVGe/exec',
    data,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const getBookingStatsKPI = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/booking-stats/kpis/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getBookingStatsTimeseries = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/booking-stats/timeseries/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getBookingStatsServices = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/booking-stats/services/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getBookingStatsBarbers = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/booking-stats/barbers/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getBookingStatsBookings = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/booking-stats/bookings/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const getMiscCategories = ({ shopId }) =>
  requestBase(
    'get',
    `/core/api/settings/${shopId}/misc-transaction-categories/`,
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const createMiscCategory = ({ shopId, categoryName }) =>
  requestBase(
    'post',
    `/core/api/settings/${shopId}/misc-transaction-categories/`,
    { name: categoryName, shopId },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const deleteMiscCategory = ({ shopId, categoryToDelete, categoryToReplace }) =>
  requestBase(
    'put',
    `/core/api/settings/${shopId}/misc-transaction-categories/`,
    { shopId, categoryToDelete, categoryToReplace },
  )
    .then((response) => response.data)
    .catch(handleResponseError);

const getMiscTransactionStats = ({ shopIdList, startDate, endDate }) =>
  requestBase('get', '/core/api/transactions/misc-stats/', null, {
    shopIdList,
    startDate,
    endDate,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listReviewsApproved = ({ shopIdList, pagination, search = '' }) =>
  requestBase('get', '/core/api/reviews/', null, {
    shopIdList,
    search,
    unprocessed: false,
    approved: true,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listReviewsDenied = ({ shopIdList, pagination, search = '' }) =>
  requestBase('get', '/core/api/reviews/', null, {
    shopIdList,
    search,
    unprocessed: false,
    denied: true,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const listReviewsUnprocessed = ({ shopIdList, pagination, search = '' }) =>
  requestBase('get', '/core/api/reviews/', null, {
    shopIdList,
    search,
    unprocessed: true,
    ...pagination,
  })
    .then((response) => response.data)
    .catch(handleResponseError);

const approveReview = ({ bookingId, approve }) =>
  requestBase('post', `/core/api/reviews/${bookingId}/approve/`, { approve })
    .then((response) => response.data)
    .catch(handleResponseError);

const respondReview = ({ bookingId, response }) =>
  requestBase('post', `/core/api/reviews/${bookingId}/respond/`, { response })
    .then((resp) => resp.data)
    .catch(handleResponseError);

const deletePortfolioImage = ({ imageId }) =>
  requestBase('delete', `/core/api/portfolio-images/${imageId}/`)
    .then((response) => response.data)
    .catch(handleResponseError);

const checkBookingPage = (url) =>
  request('head', url)
    .then((response) => response.data)
    .catch(handleResponseError);

export default {
  getEmployees,
  getServices,
  getProducts,
  listSales,
  getSale,
  purchase,
  listTillClosings,
  getTillClosing,
  closeTill,
  useProduct,
  createMiscTransaction,
  voidTransaction,
  saveService,
  deleteService,
  addService,
  saveProduct,
  deleteProduct,
  addProduct,
  getEmployee,
  saveEmployee,
  deleteEmployee,
  addEmployee,
  listUsage,
  listUsageStats,
  getUsage,
  voidUsage,
  getRevenueSummary,
  getCustomerSummary,
  getSalesSummary,
  getMiscSalesSummary,
  getUsageSummary,
  getProductServicePerformance,
  getServiceSalesPerformance,
  getProductSalesPerformance,
  getPerformanceStats,
  getTillShortage,
  getTipSummary,
  getProductInventory,
  addProductInventory,
  listInventoryInputs,
  voidInventoryInput,
  addSalaryRule,
  listSalaryRules,
  deleteSalaryRule,
  assignSalaryRule,
  listSalaryAssignments,
  calculateSalaries,
  getSalaryRule,
  unAssignSalaryRule,
  getPublicShopDetails,
  getPublicShopAppointments,
  bookAppointment,
  bookAppointmentAuthenticated,
  getSettings,
  getOnboarding,
  saveSettings,
  listBookings,
  cancelBooking,
  listSchedules,
  listEmployeeSchedules,
  listDailySchedules,
  cancelBookingCustomer,
  listClients,
  deleteClient,
  updateAvailability,
  createAvailability,
  addOrUpdateManyAvailability,
  updateAppointmentAuthenticated,
  getClient,
  downloadClients,
  listSalaryDeductions,
  addSalaryDeduction,
  voidSalaryDeduction,
  forgotPassword,
  resetPassword,
  signupEmail,
  signup,
  feedbackBookingCustomer,
  getBookingStatsKPI,
  getBookingStatsTimeseries,
  getBookingStatsServices,
  getBookingStatsBarbers,
  getBookingStatsBookings,
  getPublicShopAvailability,
  getMiscCategories,
  getMiscTransactionStats,
  createMiscCategory,
  deleteMiscCategory,
  listReviewsApproved,
  listReviewsDenied,
  listReviewsUnprocessed,
  approveReview,
  respondReview,
  deletePortfolioImage,
  messageAppointmentAuthenticated,
  saveClientNotes,
  checkBookingPage,
  getPublicShopAppointmentsV2,
  bookAppointmentV2,
  saveNextServiceBaseIDs,
  saveDisableServiceBaseIDs,
};

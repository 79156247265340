import { navigate } from 'gatsby';

import { getUser, extractToken, isBrowser } from '../utils';
// import mixpanel from '../mixpanel';
import userService from '../services/user.service';
import barbershopService from '../services/barbershop.service';

// NOTE: If you want to chain actions, just return the promis from the
// dispatch in this file, and chain it in the form calling the action
// Example: cancelBooking

let nextPurchaseItemId = 0;

const makeActionCreatorWithServiceCall = ({
  types,
  serviceMethod,
  successMessage = null,
}) => {
  return ({ f, enqueueSnackbar = null, ...args } = {}) => {
    const user = getUser();

    const defaultShopIdList =
      user && user.shopMap && user.shopMap.map((x) => x.id);

    const request = () => ({
      type: types.request,
      payload: { loading: true },
    });

    const success = (payload) => ({
      type: types.success,
      payload,
    });

    const failure = (error) => ({
      type: types.failure,
      error,
    });

    return (dispatch) => {
      if (types.request) {
        dispatch(request());
      }
      return barbershopService[serviceMethod]({
        shopIdList: defaultShopIdList,
        shopId: defaultShopIdList && defaultShopIdList[0],
        ...args,
      })
        .then((payload) => {
          if (!!successMessage && !!enqueueSnackbar) {
            enqueueSnackbar(f({ id: successMessage }), {
              variant: 'success',
            });
          }
          return dispatch(success(payload));
        })
        .catch((error) => {
          if (enqueueSnackbar && error && error.length > 0) {
            error.forEach((x) =>
              enqueueSnackbar(x, {
                variant: 'warning',
              }),
            );
          }
          if (types.failure) {
            dispatch(failure(error));
          }
          throw error;
        });
    };
  };
};

export const addPurchaseItem = (purchaseItem) => ({
  type: 'ADD_PURCHASE_ITEM',
  payload: {
    id: nextPurchaseItemId++,
    ...purchaseItem,
  },
});

export const cancelPurchase = () => ({
  type: 'CANCEL_PURCHASE',
});

export const removePurchaseItem = (purchaseItemId) => ({
  type: 'REMOVE_PURCHASE_ITEM',
  payload: {
    id: purchaseItemId,
  },
});

export const setPurchasePreviewTotal = (total) => ({
  type: 'SET_PURCHASE_PREVIEW_TOTAL',
  payload: {
    total,
  },
});

// Before each request check if token is expired.
// if it is, do a token refresh before the API call
// otherwise just call the API

export const getProducts = makeActionCreatorWithServiceCall({
  types: { success: 'GET_PRODUCTS_SUCCESS' },
  serviceMethod: 'getProducts',
});

export const getServices = makeActionCreatorWithServiceCall({
  types: { success: 'GET_SERVICES_SUCCESS' },
  serviceMethod: 'getServices',
});

export const getEmployees = makeActionCreatorWithServiceCall({
  types: { success: 'GET_EMPLOYEES_SUCCESS' },
  serviceMethod: 'getEmployees',
});

export const listSales = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_SALES_SUCCESS' },
  serviceMethod: 'listSales',
});

export const getSale = makeActionCreatorWithServiceCall({
  types: { success: 'GET_SALE_SUCCESS' },
  serviceMethod: 'getSale',
});

export const listTillClosings = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_TILL_CLOSINGS_SUCCESS' },
  serviceMethod: 'listTillClosings',
});

export const getTillClosing = makeActionCreatorWithServiceCall({
  types: { success: 'GET_TILL_CLOSING_SUCCESS' },
  serviceMethod: 'getTillClosing',
});

// TODO: Move then logic to component
export const purchase = (purchaseObj, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'PURCHASE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .purchase(purchaseObj)
      .then((sale) => {
        dispatch(success(sale));
        navigate('/app/transactions');
        enqueueSnackbar(f({ id: 'snackbarInputSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const closeTill = (closeTillData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'CLOSE_TILL_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .closeTill(closeTillData)
      .then(() => {
        dispatch(success());
        dispatch(
          getTillClosing({
            closeDate: closeTillData.closeDate,
            enqueueSnackbar,
          }),
        );
        enqueueSnackbar(f({ id: 'snackbarCloseTillSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const resetTillClosingList = () => {
  const success = () => ({
    type: 'RESET_TILL_CLOSING_LIST',
  });
  return (dispatch) => {
    dispatch(success());
  };
};

export const getOnboarding = makeActionCreatorWithServiceCall({
  types: {
    success: 'GET_ONBOARDING_SUCCESS',
    request: 'GET_ONBOARDING_REQUEST',
  },
  serviceMethod: 'getOnboarding',
});

export const useProduct = (productUseData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'USE_PRODUCT_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .useProduct(productUseData)
      .then(() => {
        dispatch(success());
        navigate('/app/usages');
        enqueueSnackbar(f({ id: 'snackbarProductUseInputSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const miscTransaction = (transactionData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'MISC_TRANSACTION_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .createMiscTransaction(transactionData)
      .then(() => {
        dispatch(success());
        navigate('/app/transactions');
        enqueueSnackbar(f({ id: 'snackbarInputSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const voidTransaction = (voidData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'VOID_TRANSACTION_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .voidTransaction(voidData)
      .then(() => {
        dispatch(success());
        window.history.back();
        enqueueSnackbar(f({ id: 'snackbarVoidSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const saveService = (serviceId, serviceData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'SAVE_SERVICE_SUCCESS',
  });
  return (dispatch) =>
    barbershopService
      .saveService(serviceId, serviceData)
      .then(() => {
        dispatch(success());
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
};

export const deleteService = (serviceId, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'DELETE_SERVICE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .deleteService(serviceId)
      .then(() => {
        dispatch(success());
        dispatch(getServices());
        navigate('/app/services');
        enqueueSnackbar(f({ id: 'snackbarDeleteSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const addService = (serviceData, enqueueSnackbar, onboarding, f) => {
  const success = () => ({
    type: 'ADD_SERVICE_SUCCESS',
  });
  return (dispatch) =>
    barbershopService
      .addService(serviceData)
      .then((service) => {
        dispatch(success());
        dispatch(getServices());
        if (!onboarding) {
          navigate('/app/services');
          enqueueSnackbar(f({ id: 'snackbarAddSuccess' }), {
            variant: 'success',
          });
        } else {
          dispatch(getOnboarding());
        }
        return service;
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
};

export const saveProduct = (productId, productData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'SAVE_PRODUCT_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .saveProduct(productId, productData)
      .then(() => {
        dispatch(success());
        dispatch(getProducts());
        navigate('/app/products');
        enqueueSnackbar(f({ id: 'snackbarModifySuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const deleteProduct = (productId, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'DELETE_PRODUCT_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .deleteProduct(productId)
      .then(() => {
        dispatch(success());
        dispatch(getProducts());
        navigate('/app/products');
        enqueueSnackbar(f({ id: 'snackbarDeleteSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const addProduct = (productData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'ADD_PRODUCT_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .addProduct(productData)
      .then(() => {
        dispatch(success());
        dispatch(getProducts());
        navigate('/app/products');
        enqueueSnackbar(f({ id: 'snackbarAddSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const getEmployee = (
  employeeId,
  shopIdList = getUser().shopMap && getUser().shopMap.map((x) => x.id),
  enqueueSnackbar,
) => {
  const success = (employeeDetails) => ({
    type: 'GET_EMPLOYEE_DETAILS_SUCCESS',
    payload: employeeDetails,
  });
  return (dispatch) => {
    return barbershopService
      .getEmployee(employeeId, shopIdList)
      .then((employeeDetails) => {
        dispatch(success(employeeDetails));
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const saveEmployee = (
  employeeId,
  employeeData,
  enqueueSnackbar,
  f,
  shopIdList = getUser().shopMap && getUser().shopMap.map((x) => x.id),
) => {
  const success = () => ({
    type: 'SAVE_EMPLOYEE_SUCCESS',
  });
  return (dispatch) => {
    return barbershopService
      .saveEmployee(employeeId, employeeData, shopIdList)
      .then(() => {
        dispatch(success());
        dispatch(getEmployees());
        navigate('/app/employees');
        enqueueSnackbar(f({ id: 'snackbarModifySuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const deleteEmployee = (employeeId, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'DELETE_EMPLOYEE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .deleteEmployee(employeeId)
      .then(() => {
        dispatch(success());
        dispatch(getEmployees());
        navigate('/app/employees');
        enqueueSnackbar(f({ id: 'snackbarDeleteSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const addEmployee = (employeeData, enqueueSnackbar, onboarding, f) => {
  const success = () => ({
    type: 'ADD_EMPLOYEE_SUCCESS',
  });
  return (dispatch) => {
    return barbershopService
      .addEmployee(employeeData)
      .then(() => {
        dispatch(success());
        dispatch(getEmployees());
        if (!onboarding) {
          navigate('/app/employees');
          enqueueSnackbar(f({ id: 'snackbarAddSuccess' }), {
            variant: 'success',
          });
        } else {
          dispatch(getOnboarding());
        }
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const listUsage = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_USAGE_SUCCESS' },
  serviceMethod: 'listUsage',
});

export const listUsageStats = (
  startDate,
  endDate,
  shopIdList = getUser().shopMap && getUser().shopMap.map((x) => x.id),
  enqueueSnackbar,
) => {
  const success = (usages) => ({
    type: 'LIST_USAGE_STATS_SUCCESS',
    payload: usages,
  });
  return (dispatch) => {
    barbershopService
      .listUsageStats(shopIdList, startDate, endDate)
      .then((usages) => dispatch(success(usages)))
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const getUsage = (usageId, enqueueSnackbar) => {
  const success = (usage) => ({
    type: 'GET_USAGE_SUCCESS',
    payload: usage,
  });
  return (dispatch) => {
    barbershopService
      .getUsage(usageId)
      .then((usage) => dispatch(success(usage)))
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const voidUsage = (usageId, voidData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'VOID_USAGE_SUCCESS',
  });
  return (dispatch) => {
    return barbershopService
      .voidUsage(usageId, voidData)
      .then(() => {
        dispatch(success());
        window.history.back();
        enqueueSnackbar(f({ id: 'snackbarVoidSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const getRevenueSummary = makeActionCreatorWithServiceCall({
  types: { success: 'GET_REVENUE_SUMMARY_SUCCESS' },
  serviceMethod: 'getRevenueSummary',
});

export const getCustomerSummary = makeActionCreatorWithServiceCall({
  types: { success: 'GET_CUSTOMER_SUMMARY_SUCCESS' },
  serviceMethod: 'getCustomerSummary',
});

export const getSalesSummary = makeActionCreatorWithServiceCall({
  types: { success: 'GET_SALES_SUMMARY_SUCCESS' },
  serviceMethod: 'getSalesSummary',
});

export const getMiscSalesSummary = makeActionCreatorWithServiceCall({
  types: { success: 'GET_MISC_SALES_SUMMARY_SUCCESS' },
  serviceMethod: 'getMiscSalesSummary',
});

export const getUsageSummary = makeActionCreatorWithServiceCall({
  types: { success: 'GET_USAGE_SUMMARY_SUCCESS' },
  serviceMethod: 'getUsageSummary',
});

export const getTipSummary = makeActionCreatorWithServiceCall({
  types: { success: 'GET_TIP_SUMMARY_SUCCESS' },
  serviceMethod: 'getTipSummary',
});

export const getProductServicePerformance = makeActionCreatorWithServiceCall({
  types: { success: 'GET_PRODUCTSERVICE_PERFORMANCE_SUCCESS' },
  serviceMethod: 'getProductServicePerformance',
});

export const getServiceSalesPerformance = makeActionCreatorWithServiceCall({
  types: { success: 'GET_SERVICE_SALES_PERFORMANCE_SUCCESS' },
  serviceMethod: 'getServiceSalesPerformance',
});

export const getProductSalesPerformance = makeActionCreatorWithServiceCall({
  types: { success: 'GET_PRODUCT_SALES_PERFORMANCE_SUCCESS' },
  serviceMethod: 'getProductSalesPerformance',
});

export const getPerformanceStats = makeActionCreatorWithServiceCall({
  types: { success: 'GET_PERFORMANCE_STATS_SUCCESS' },
  serviceMethod: 'getPerformanceStats',
});

export const getTillShortage = makeActionCreatorWithServiceCall({
  types: { success: 'GET_PERFORMANCE_TILL_SHORTAGE_SUCCESS' },
  serviceMethod: 'getTillShortage',
});

export const getProductInventory = makeActionCreatorWithServiceCall({
  types: { success: 'GET_PRODUCT_INVENTORY_SUCCESS' },
  serviceMethod: 'getProductInventory',
});

export const getMiscTransactionStats = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_MISC_TRANSACTION_STATS_SUCCESS' },
  serviceMethod: 'getMiscTransactionStats',
});

export const addMultiProductInventory = (
  inventoryDataList,
  enqueueSnackbar,
  f,
) => {
  return (dispatch) => {
    const callService = new Promise((resolve, reject) => {
      let requestCounter = 0;
      let successCounter = 0;
      let failedCounter = 0;
      inventoryDataList.forEach((inventoryData) => {
        requestCounter += 1;
        barbershopService
          .addProductInventory(inventoryData)
          .then(() => {
            successCounter += 1;
            if (successCounter === requestCounter) {
              resolve(true);
            } else if (successCounter + failedCounter === requestCounter) {
              resolve(false);
            }
          })
          .catch((error) => {
            failedCounter += 1;
            if (successCounter + failedCounter === requestCounter) {
              resolve(false);
            }
          });
      });
    });

    return callService.then((value) => {
      if (value) {
        enqueueSnackbar(f({ id: 'snackbarAddProductInventorySuccess' }), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(f({ id: 'snackbarAddProductInventoryError' }), {
          variant: 'warning',
        });
      }
    });
  };
};

export const listInventoryInputs = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_INVENTORY_INPUT_SUCCESS' },
  serviceMethod: 'listInventoryInputs',
});

export const voidInventoryInput = (voidData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'VOID_INVENTORY_INPUT_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .voidInventoryInput(voidData)
      .then(() => {
        dispatch(success());
        window.history.back();
        enqueueSnackbar(f({ id: 'snackbarVoidSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const listSalaryRules = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_SALARY_RULES_SUCCESS' },
  serviceMethod: 'listSalaryRules',
});

export const addSalaryRule = (ruleData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'ADD_SALARY_RULE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .addSalaryRule(ruleData)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(f({ id: 'snackbarAddRuleSuccess' }), {
          variant: 'success',
        });
        dispatch(listSalaryRules({ enqueueSnackbar }));
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const deleteSalaryRule = (ruleId, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'DELETE_SALARY_RULE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .deleteSalaryRule(ruleId)
      .then(() => {
        dispatch(success());
        dispatch(listSalaryRules({ enqueueSnackbar }));
        enqueueSnackbar(f({ id: 'snackbarDeleteSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const listSalaryAssignments = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_SALARY_ASSIGNMENTS_SUCCESS' },
  serviceMethod: 'listSalaryAssignments',
});

export const assignSalaryRule = (assignmentData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'ASSIGN_SALARY_RULE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .assignSalaryRule(assignmentData)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(f({ id: 'snackbarAssignRuleSuccess' }), {
          variant: 'success',
        });
        dispatch(listSalaryAssignments({ enqueueSnackbar }));
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const unAssignSalaryRule = (
  ruleId,
  employeeId,
  shopIdList = getUser().shopMap && getUser().shopMap.map((x) => x.id),
  enqueueSnackbar,
  f,
) => {
  const success = () => ({
    type: 'UNASSIGN_SALARY_RULE_SUCCESS',
  });
  return (dispatch) => {
    barbershopService
      .unAssignSalaryRule(ruleId, employeeId, shopIdList)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(f({ id: 'snackbarUnassignRuleSuccess' }), {
          variant: 'success',
        });
        dispatch(listSalaryAssignments({ enqueueSnackbar }));
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const calculateSalaries = makeActionCreatorWithServiceCall({
  types: { success: 'CALCULATE_SALARY_SUCCESS' },
  serviceMethod: 'calculateSalaries',
});

export const getSalaryRule = (salaryRuleId, enqueueSnackbar) => {
  const success = (ruleData) => ({
    type: 'GET_SALARY_RULE_SUCCESS',
    payload: ruleData,
  });
  return (dispatch) => {
    barbershopService
      .getSalaryRule(salaryRuleId)
      .then((salaries) => dispatch(success(salaries)))
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const getPublicShopDetails = (shopId, enqueueSnackbar) => {
  const success = (shopData) => ({
    type: 'GET_PUBLIC_SHOP_DETAILS_SUCCESS',
    payload: shopData,
  });
  const failure = () => ({
    type: 'GET_PUBLIC_SHOP_DETAILS_FAILED',
  });
  return (dispatch) => {
    barbershopService
      .getPublicShopDetails(shopId)
      .then((shopData) => dispatch(success(shopData)))
      .catch((error) => {
        if (enqueueSnackbar) {
          error.map((x) =>
            enqueueSnackbar(x, {
              variant: 'warning',
            }),
          );
        }
        dispatch(failure());
      });
  };
};

export const getPublicShopAppointments = makeActionCreatorWithServiceCall({
  types: {
    success: 'GET_PUBLIC_SHOP_APPOINTMENTS_SUCCESS',
    failure: 'GET_PUBLIC_SHOP_APPOINTMENTS_FAILED',
  },
  serviceMethod: 'getPublicShopAppointments',
});

export const getPublicShopAppointmentsV2 = makeActionCreatorWithServiceCall({
  types: {
    success: 'GET_PUBLIC_SHOP_APPOINTMENTS_SUCCESS',
    failure: 'GET_PUBLIC_SHOP_APPOINTMENTS_FAILED',
  },
  serviceMethod: 'getPublicShopAppointmentsV2',
});

export const bookAppointment = (shopId, bookingData, enqueueSnackbar, f) => {
  const started = () => ({
    type: 'API_REQUEST_STARTED',
  });
  const success = () => ({
    type: 'API_REQUEST_SUCCEEDED',
  });
  const failed = (error) => ({
    type: 'API_REQUEST_FAILED',
    payload: error,
  });
  return (dispatch) => {
    dispatch(started());
    return barbershopService
      .bookAppointment(shopId, bookingData)
      .then(() => {
        enqueueSnackbar(f({ id: 'snackbarBookingSuccess' }), {
          variant: 'success',
        });
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failed(error));
      });
  };
};

export const bookAppointmentV2 = (shopId, bookingData, enqueueSnackbar, f) => {
  const started = () => ({
    type: 'API_REQUEST_STARTED',
  });
  const success = () => ({
    type: 'API_REQUEST_SUCCEEDED',
  });
  const failed = (error) => ({
    type: 'API_REQUEST_FAILED',
    payload: error,
  });
  return (dispatch) => {
    dispatch(started());
    return barbershopService
      .bookAppointmentV2(shopId, bookingData)
      .then(() => {
        enqueueSnackbar(f({ id: 'snackbarBookingSuccess' }), {
          variant: 'success',
        });
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failed(error));
      });
  };
};

export const bookAppointmentAuthenticated = (
  shopIdList,
  bookingData,
  enqueueSnackbar,
  f,
) => {
  const started = () => ({
    type: 'API_REQUEST_STARTED',
  });
  const success = () => ({
    type: 'API_REQUEST_SUCCEEDED',
  });
  const failed = (error) => ({
    type: 'API_REQUEST_FAILED',
    payload: error,
  });
  return (dispatch) => {
    dispatch(started());
    return barbershopService
      .bookAppointmentAuthenticated(shopIdList, bookingData)
      .then(() => {
        enqueueSnackbar(f({ id: 'snackbarBookingSuccess' }), {
          variant: 'success',
        });
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failed(error));
      });
  };
};

export const updateAppointmentAuthenticated = (
  shopId,
  bookingId,
  bookingData,
  enqueueSnackbar,
  f,
) => {
  const started = () => ({
    type: 'API_REQUEST_STARTED',
  });
  const success = () => ({
    type: 'API_REQUEST_SUCCEEDED',
  });
  const failed = (error) => ({
    type: 'API_REQUEST_FAILED',
    payload: error,
  });
  return (dispatch) => {
    dispatch(started());
    return barbershopService
      .updateAppointmentAuthenticated(shopId, bookingId, bookingData)
      .then(() => {
        enqueueSnackbar(f({ id: 'snackbarModifySuccess' }), {
          variant: 'success',
        });
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failed(error));
        error.map((x) => {
          const parsed = JSON.parse(x);
          if (parsed.non_field_errors) {
            enqueueSnackbar(parsed.non_field_errors, {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(x, {
              variant: 'warning',
            });
          }
        });
      });
  };
};

export const getSettings = makeActionCreatorWithServiceCall({
  types: { success: 'GET_SETTINGS_SUCCESS', request: 'GET_SETTINGS_REQUEST' },
  serviceMethod: 'getSettings',
});

export const saveSettings = makeActionCreatorWithServiceCall({
  types: { success: 'SAVE_SETTINGS_SUCCESS' },
  serviceMethod: 'saveSettings',
  successMessage: 'snackbarModifySuccess',
});

export const listBookings = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_BOOKINGS_SUCCESS' },
  serviceMethod: 'listBookings',
});

export const cancelBooking = makeActionCreatorWithServiceCall({
  types: { success: 'CANCEL_BOOKING_SUCCESS' },
  serviceMethod: 'cancelBooking',
});

export const cancelBookingCustomer = (
  shopId,
  token,
  cancelReason,
  enqueueSnackbar,
) => {
  const started = () => ({
    type: 'API_REQUEST_STARTED',
  });
  const success = () => ({
    type: 'API_REQUEST_SUCCEEDED',
  });
  const failed = (error) => ({
    type: 'API_REQUEST_FAILED',
    payload: error,
  });
  return (dispatch) => {
    dispatch(started());
    return barbershopService
      .cancelBookingCustomer(shopId, token, cancelReason)
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failed(error));
        error
          .map((x) => JSON.parse(x))
          .forEach((x) => {
            if (x.non_field_errors) {
              enqueueSnackbar(x.non_field_errors.join(', '), {
                variant: 'warning',
              });
            } else {
              enqueueSnackbar(JSON.stringify(x), {
                variant: 'warning',
              });
            }
          });
        throw error;
      });
  };
};

export const feedbackBookingCustomer = (
  shopId,
  token,
  feedback,
  stars,
  enqueueSnackbar,
) => {
  const started = () => ({
    type: 'API_REQUEST_STARTED',
  });
  const success = () => ({
    type: 'API_REQUEST_SUCCEEDED',
  });
  const failed = (error) => ({
    type: 'API_REQUEST_FAILED',
    payload: error,
  });
  return (dispatch) => {
    dispatch(started());
    return barbershopService
      .feedbackBookingCustomer(shopId, token, feedback, stars)
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failed(error));
        error
          .map((x) => JSON.parse(x))
          .forEach((x) => {
            if (x.non_field_errors) {
              enqueueSnackbar(x.non_field_errors.join(', '), {
                variant: 'warning',
              });
            } else {
              enqueueSnackbar(JSON.stringify(x), {
                variant: 'warning',
              });
            }
          });
        throw error;
      });
  };
};

export const listSchedules = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_SCHEDULES_SUCCESS' },
  serviceMethod: 'listSchedules',
});

export const listEmployeeSchedules = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_EMPLOYEE_SCHEDULES_SUCCESS' },
  serviceMethod: 'listEmployeeSchedules',
});

export const listDailySchedules = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_DAILY_SCHEDULES_SUCCESS' },
  serviceMethod: 'listDailySchedules',
});

export const resetSalaryRule = () => {
  const success = () => ({
    type: 'RESET_SALARY_RULE',
  });
  return (dispatch) => {
    dispatch(success());
  };
};

export const listClients = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_CLIENTS_SUCCESS' },
  serviceMethod: 'listClients',
});

export const saveClientNotes = makeActionCreatorWithServiceCall({
  types: { success: 'SAVE_CLIENT_NOTES_SUCCESS' },
  serviceMethod: 'saveClientNotes',
});

export const downloadClients = makeActionCreatorWithServiceCall({
  types: { success: 'DOWNLOAD_CLIENTS_SUCCESS' },
  serviceMethod: 'downloadClients',
});

export const getClient = makeActionCreatorWithServiceCall({
  types: { success: 'GET_CLIENT_SUCCESS' },
  serviceMethod: 'getClient',
});

export const getBookingStatsKPI = makeActionCreatorWithServiceCall({
  types: { success: 'GET_BOOKING_STATS_KPI' },
  serviceMethod: 'getBookingStatsKPI',
});

export const getBookingStatsTimeseries = makeActionCreatorWithServiceCall({
  types: { success: 'GET_BOOKING_STATS_TIMESERIES' },
  serviceMethod: 'getBookingStatsTimeseries',
});

export const getBookingStatsServices = makeActionCreatorWithServiceCall({
  types: { success: 'GET_BOOKING_STATS_SERVICES' },
  serviceMethod: 'getBookingStatsServices',
});

export const getBookingStatsBarbers = makeActionCreatorWithServiceCall({
  types: { success: 'GET_BOOKING_STATS_BARBERS' },
  serviceMethod: 'getBookingStatsBarbers',
});

export const getBookingStatsBookings = makeActionCreatorWithServiceCall({
  types: { success: 'GET_BOOKING_STATS_BOOKINGS' },
  serviceMethod: 'getBookingStatsBookings',
});

export const deleteClient = makeActionCreatorWithServiceCall({
  types: { success: 'DELETE_CLIENT_SUCCESS' },
  serviceMethod: 'deleteClient',
  successMessage: 'snackbarDeleteSuccess',
});

export const updateAvailability = makeActionCreatorWithServiceCall({
  types: { success: 'UPDATE_AVAILABILITY_SUCCESS' },
  serviceMethod: 'updateAvailability',
  successMessage: 'snackbarModifySuccess',
});

export const createAvailability = makeActionCreatorWithServiceCall({
  types: { success: 'CREATE_AVAILABILITY_SUCCESS' },
  serviceMethod: 'createAvailability',
  successMessage: 'snackbarCreateSuccess',
});

export const addOrUpdateManyAvailability = makeActionCreatorWithServiceCall({
  types: { success: 'UPDATE_MULTI_AVAILABILITY_SUCCESS' },
  serviceMethod: 'addOrUpdateManyAvailability',
  successMessage: 'snackbarModifySuccess',
});

export const addSalaryDeduction = makeActionCreatorWithServiceCall({
  types: { success: 'ADD_SALARY_DEDUCTION_SUCCESS' },
  serviceMethod: 'addSalaryDeduction',
  successMessage: 'snackbarCreateSuccess',
});

export const listSalaryDeductions = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_SALARY_DEDUCTIONS_SUCCESS' },
  serviceMethod: 'listSalaryDeductions',
});

export const voidSalaryDeduction = makeActionCreatorWithServiceCall({
  types: { success: 'VOID_SALARY_DEDUCTION_SUCCESS' },
  serviceMethod: 'voidSalaryDeduction',
  successMessage: 'snackbarDeleteSuccess',
});

export const getPublicShopAvailability = makeActionCreatorWithServiceCall({
  types: {
    success: 'GET_PUBLIC_SHOP_AVAILABILITY_SUCCESS',
    failure: 'GET_PUBLIC_SHOP_AVAILABILITY_FAILED',
  },
  serviceMethod: 'getPublicShopAvailability',
});

export const getMiscCategories = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_MISC_CATEGORIES_SUCCESS' },
  serviceMethod: 'getMiscCategories',
});

export const createMiscCategory = makeActionCreatorWithServiceCall({
  types: { success: '' },
  serviceMethod: 'createMiscCategory',
});

export const deleteMiscCategory = makeActionCreatorWithServiceCall({
  types: { success: '' },
  serviceMethod: 'deleteMiscCategory',
});

export const listReviewsApproved = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_REVIEWS_APPROVED_SUCCESS' },
  serviceMethod: 'listReviewsApproved',
});

export const listReviewsDenied = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_REVIEWS_DENIED_SUCCESS' },
  serviceMethod: 'listReviewsDenied',
});

export const listReviewsUnprocessed = makeActionCreatorWithServiceCall({
  types: { success: 'LIST_REVIEWS_UNPROCESSED_SUCCESS' },
  serviceMethod: 'listReviewsUnprocessed',
});

export const approveReview = makeActionCreatorWithServiceCall({
  types: { success: 'APPROVE_REVIEW_SUCCESS' },
  serviceMethod: 'approveReview',
});

export const respondReview = makeActionCreatorWithServiceCall({
  types: { success: 'RESPOND_REVIEW_SUCCESS' },
  serviceMethod: 'respondReview',
});

export const messageBooking = makeActionCreatorWithServiceCall({
  types: { success: 'MESSAGE_BOOKING_SUCCESS' },
  serviceMethod: 'messageAppointmentAuthenticated',
});

export const deletePortfolioImage = makeActionCreatorWithServiceCall({
  types: { success: 'DELETE_PORTFOLIO_IMAGE_SUCCESS' },
  serviceMethod: 'deletePortfolioImage',
  successMessage: 'snackbarDeleteSuccess',
});

export const saveNextServiceBaseIDs = makeActionCreatorWithServiceCall({
  types: { success: 'SAVE_NEXT_SERVICE_BASE_IDS_SUCCESS' },
  serviceMethod: 'saveNextServiceBaseIDs',
});

export const saveDisableServiceBaseIDs = makeActionCreatorWithServiceCall({
  types: { success: 'SAVE_DISABLE_SERVICE_BASE_IDS_SUCCESS' },
  serviceMethod: 'saveDisableServiceBaseIDs',
});

export const checkBookingPage = (url, f) => {
  const success = () => ({
    type: 'CHECK_BOOKING_PAGE_SUCCESS',
  });
  const failure = () => ({
    type: 'CHECK_BOOKING_PAGE_FAILURE',
  });
  return (dispatch) => {
    return barbershopService
      .checkBookingPage(url)
      .then(() => {
        dispatch(success());
        window.open(url);
      })
      .catch((error) => {
        dispatch(failure());
        window.alert(f({ id: 'errorWebsiteStillBuilding' }));
      });
  };
};

export const login = (username, password, enqueueSnackbar, f) => {
  function request(user) {
    return {
      type: 'LOGIN_REQUEST',
      user,
    };
  }

  function success(user) {
    return {
      type: 'LOGIN_SUCCESS',
      user,
    };
  }

  function failure(error) {
    return {
      type: 'LOGIN_FAILURE',
      error,
    };
  }

  return (dispatch) => {
    dispatch(
      request({
        username,
      }),
    );
    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        dispatch(getServices());
        const userLoaded = getUser();
        if (userLoaded?.shopMap?.filter((x) => x.management).length > 0) {
          dispatch(getProducts());
        }
        dispatch(getEmployees());
        dispatch(() => navigate('/app/'));
      },
      (error) => {
        dispatch(failure(error));
        if (error) {
          error.map((x) => {
            const parsed = JSON.parse(x);
            if (parsed && parsed.non_field_errors) {
              enqueueSnackbar(parsed.non_field_errors, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar(f({ id: 'snackbarLoginError' }), {
                variant: 'error',
              });
            }
          });
        }
      },
    );
  };
};

export const loginAs = (tokenPair) => {
  function request(user) {
    return {
      type: 'LOGIN_REQUEST',
      user,
    };
  }

  function success(user) {
    return {
      type: 'LOGIN_SUCCESS',
      user,
    };
  }

  return (dispatch) => {
    dispatch(request());

    const userWithDetails = extractToken(
      JSON.parse(atob(tokenPair.replace(/"/g, ''))),
    );
    localStorage.setItem('user', JSON.stringify(userWithDetails));

    dispatch(success(userWithDetails));
    dispatch(getServices());
    const user = getUser();

    // if (user) {
    //   mixpanel.identify(user.userId);
    //   mixpanel.people.set({
    //     $name: `superadmin-${user.username}`,
    //     $email: user.email,
    //     shopId: user.shopId,
    //     shopName: user.shopName,
    //     role: user.role,
    //   });
    // }

    if (user?.shopMap?.filter((x) => x.management).length > 0) {
      dispatch(getProducts());
    }
    dispatch(getEmployees());
    dispatch(() => navigate('/app/'));
  };
};

export const logout = (enqueueSnackbar, f, locale) => {
  const success = () => ({
    type: 'LOGOUT',
  });

  return (dispatch) => {
    if (isBrowser) localStorage.removeItem('user');
    dispatch(success());
    navigate(`/${locale}/app/login`);
    enqueueSnackbar(f({ id: 'snackbarLogoutSuccess' }), {
      variant: 'success',
    });
  };
};

export const forgotPassword = (forgotPasswordData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'FORGOT_PASSWORD_SUCCESS',
  });
  return (dispatch) => {
    return barbershopService
      .forgotPassword(forgotPasswordData)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(f({ id: 'snackbarForgotPasswordSent' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const resetPassword = (resetPasswordData, enqueueSnackbar, f) => {
  const success = () => ({
    type: 'RESET_PASSWORD_SUCCESS',
  });
  return (dispatch) => {
    return barbershopService
      .resetPassword(resetPasswordData)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(f({ id: 'snackbarResetPasswordSuccess' }), {
          variant: 'success',
        });
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const signupEmail = (signupData, enqueueSnackbar) => {
  const success = () => ({
    type: 'SIGNUP_EMAIL_SUCCESS',
  });
  return (dispatch) => {
    return barbershopService
      .signupEmail(signupData)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(
          'Munkatársunk hamarosan felveszi veled a kapcsolatot a megadott email címen',
          {
            variant: 'success',
          },
        );
      })
      .catch((error) =>
        error.map((x) =>
          enqueueSnackbar(x, {
            variant: 'warning',
          }),
        ),
      );
  };
};

export const signup = (signupData, enqueueSnackbar, f) => {
  const request = () => ({
    type: 'SIGNUP_REQUEST',
  });

  const success = () => ({
    type: 'SIGNUP_SUCCESS',
  });

  const failure = (error) => ({
    type: 'SIGNUP_FAILURE',
    error,
  });

  return (dispatch) => {
    dispatch(request());
    return barbershopService
      .signup(signupData)
      .then(() => {
        dispatch(success());
        enqueueSnackbar(f({ id: 'snackbarResetSignupSuccess' }), {
          variant: 'success',
        });
        navigate('/');
      })
      .catch((error) => {
        dispatch(failure(error));
        enqueueSnackbar(f({ id: 'snackbarResetSignupError' }), {
          variant: 'error',
        });
      });
  };
};
